.container {
  background-color: light-dark(
    var(--mantine-color-gray-1),
    var(--mantine-color-dark-6)
  );
}

.centerContainer {
  width: 100%;
  height: 100vh;
}

.stack {
  width: 500px;
  margin-top: -40px;
}

.formContainer {
  width: 100%;
}

.brandLogo {
  width: 42px;
  margin-bottom: 14px;
}

.alertRoot {
  border-color: var(--mantine-color-red-1);
  width: 100%;
}
