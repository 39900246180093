.login_container__ACVG2 {
  background-color: var(--mantine-color-gray-1);
}
  [data-mantine-color-scheme='dark'] .login_container__ACVG2 {
  background-color: var(--mantine-color-dark-6);
}

.login_centerContainer__ah7JI {
  width: 100%;
  height: 100vh;
}

.login_stack__exRBk {
  width: 500px;
  margin-top: -40px;
}

.login_formContainer__9tlUA {
  width: 100%;
}

.login_brandLogo__GciZo {
  width: 42px;
  margin-bottom: 14px;
}

.login_alertRoot__RiuCE {
  border-color: var(--mantine-color-red-1);
  width: 100%;
}

